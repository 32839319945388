import { onMount } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { EventManager } from "~/data/events";
import { Cookie, Header } from "~/types";
import { clearCookie } from "~/utils/client_cookie";
import { setIsFreshLogin } from "~/components/brand_l2/header";
import { defaultHeaders, setDefaultHeaders } from "~/data/auth";

export default function Logout() {
  onMount(() => {
    console.log(`Logging out. Clearing local cookie and local storage`);
    setIsFreshLogin(false);
    clearCookie(Cookie.SessionId);
    localStorage.removeItem("sessionId");
    EventManager.logout();

    const headers = defaultHeaders();
    delete headers[Header.SessionId];
    setDefaultHeaders({
      ...headers,
    });
  });

  return <ClientOnlyComponent component={ClientComponent.PostLogoutRedirect} />;
}
